// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-how-is-jer-assessed-js": () => import("./../src/pages/how-is-jer-assessed.js" /* webpackChunkName: "component---src-pages-how-is-jer-assessed-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-what-is-jer-pecunia-non-olet-js": () => import("./../src/pages/what-is-jer-pecunia-non-olet.js" /* webpackChunkName: "component---src-pages-what-is-jer-pecunia-non-olet-js" */),
  "component---src-pages-who-is-jeffrey-einstein-js": () => import("./../src/pages/who-is-jeffrey-einstein.js" /* webpackChunkName: "component---src-pages-who-is-jeffrey-einstein-js" */)
}

